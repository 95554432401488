import React, {useEffect} from 'react'
import "styles/style.scss"
import {Link} from "gatsby"

import {graphql} from 'gatsby'

// var array = require('lodash/array')

import jsonData from 'data'
import {Issue, Page, ArticleSplitter} from 'types'
import {IPubRequest} from 'interfaces'
import {BarTitle} from 'components/Bootstrap-plus'
import { Layout, MagazineCard, CategoryIcon} from 'components'
// import {getEbaySmartPlacement} from 'types/JellyLinks'

const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );


const MagCardWithArticles = (
  {iss, className} : {iss : Issue, className: string}
) => {

  const SideArticles = () => (
    <ul className='w-50 mb-5 px-2'>
        {iss.articles.slice(0,3).map((article, ctr) => 
          <>
            {ctr !== 0 && <hr></hr>}
            <li className='flex-center-col'>              
              <CategoryIcon category={article.category} />
              <Link to={article.url}
                // state={{widget: <Jelly style={{width:'100%', height:'100%'}} jellyInfo={getEbaySmartPlacement(article)}/> }}
              >
                <p>{article.title}</p>
              </Link>
            </li>
          </>
        )}
      </ul>
  )

  return (
      <MagazineCard style={{borderRadius: '0.25rem'}} className='mx-auto col-xl-2 col-md-3 col-sm-5 col-11 m-5 h-100 hover-shadow' issue={iss} />
    // <div className={className}>
    //   <MagazineCard className='mx-auto w-50 h-100 hover-shadow' issue={iss} />
    //   {iss.hasArticles && <SideArticles />}
    // </div>
  )
}

const IssueRow = ({iss1, iss2, iss3}) => {
  
  const magClass = 'magCard m-2 mt-5'

  return (
    <div className='row flex-center-row mx-auto'>
      <MagCardWithArticles className={magClass} iss={iss1} />
      {iss2 ? <MagCardWithArticles className={magClass} iss={iss2} /> : ''}
      {iss3 ? <MagCardWithArticles className={magClass} iss={iss3} /> : ''}
    </div>
  );
};

export default ({ data }: { data: IPubRequest }) => {

  const articles = ArticleSplitter.createAndSeparateArticles(data.allMarkdownRemark.edges)
  let issues: Issue[] = jsonData.links.issues

  issues.forEach((issue: Issue) => {
    issue.setImgProps(data)
    issue.articles = articles[issue.name]
  })

  let arr = chunk(issues.slice(1, issues.length), 3)

  // useEffect(() => {
  //     window.scrollTo(0,0)
  // }, [data])

  data.site.siteMetadata.title = `${data.site.siteMetadata.title} | Publications`  
  data.site.siteMetadata.siteUrl = `${data.site.siteMetadata.siteUrl}/publications`
  return (
    <Layout siteMetadata={data.site.siteMetadata} page={Page.publications}>
          
      <div className='page'>
        <div className='flex-center-col mt-5 w-100'>
          <h1 className='h1-mobile mt-5'>{`${issues[0].displayName} Issue`}</h1>
          <MagCardWithArticles className='biggerMag my-5' iss={issues[0]} />
        </div>
        <BarTitle className='h1-mobile' text='Past Publications' />
        {
          arr.map(([iss1, iss2, iss3] : [Issue, Issue, Issue]) => (
            <IssueRow iss1={iss1} iss2={iss2} iss3={iss3} />
          ))
        }
      </div>
      
    </Layout>
  )
}

export const pageQuery = graphql`
  query PublicationPageQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            category
            subCategory
            author
            issue
            givenRank
            date
          }
        }
      }
    }
    allFile(filter: {
      extension: {regex: "/(jpg)|(jpeg)|(png)/"},
      name: {regex: "/[(Spring)|(Winter)|(Fall)|(Summer)]-[0-9]{4,4}/"}
    }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
          name
        }
      }
    }
    site {
      siteMetadata {
        author
        authorDescription
        description
        title
        themeColor
        siteUrl
        image
      }
    }
  }
`